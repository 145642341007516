import { default as loginWQgDBaf4HlMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/(auth)/login.vue?macro=true";
import { default as registerRFI4TRNFIVMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/(auth)/register.vue?macro=true";
import { default as detailsvtXk0JWZ1BMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/apps/[id]/details.vue?macro=true";
import { default as indexqXijVmUYfpMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/apps/[id]/index.vue?macro=true";
import { default as assign_45appwswDXTLhdMMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/apps/assign-app.vue?macro=true";
import { default as createVno21sdN2tMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/apps/create.vue?macro=true";
import { default as indexCbbAuArSZKMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/apps/index.vue?macro=true";
import { default as appsuIC4lFTaPZMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/apps.vue?macro=true";
import { default as indexOnL5srIQBrMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/clients/[id]/index.vue?macro=true";
import { default as assign_45appNpPC22DV59Meta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/clients/assign-app.vue?macro=true";
import { default as createTOctfPgHMaMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/clients/create.vue?macro=true";
import { default as indexzg7k96MQGWMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/clients/index.vue?macro=true";
import { default as setup_45confirmationheSlF9Cax2Meta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/clients/setup-confirmation.vue?macro=true";
import { default as clientsdi6PLhxvRGMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/clients.vue?macro=true";
import { default as indexeATnvOQxm9Meta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/index.vue?macro=true";
import { default as index5v4NfaAaxwMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/invitation/index.vue?macro=true";
import { default as receivede8WUnLl17sMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/invitation/received.vue?macro=true";
import { default as _01sOoiAH1oNUMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/onboarding/01.vue?macro=true";
import { default as _02lnU7J60UlbMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/onboarding/02.vue?macro=true";
import { default as _03IY5bz2ZdnXMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/onboarding/03.vue?macro=true";
import { default as indexioZTg9yQsQMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/onboarding/index.vue?macro=true";
import { default as overview_45clientR8oQldzbscMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/overview-client.vue?macro=true";
import { default as overviewjFGNaEHPTtMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/overview.vue?macro=true";
import { default as _91id_93UB1zgTlTYIMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/templates/[id].vue?macro=true";
import { default as createar7BoPp4ryMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/templates/create.vue?macro=true";
import { default as indexpl3I0y6iFPMeta } from "/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/templates/index.vue?macro=true";
export default [
  {
    name: "login",
    path: "/login",
    meta: loginWQgDBaf4HlMeta || {},
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/(auth)/login.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerRFI4TRNFIVMeta || {},
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/(auth)/register.vue").then(m => m.default || m)
  },
  {
    name: appsuIC4lFTaPZMeta?.name,
    path: "/apps",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/apps.vue").then(m => m.default || m),
    children: [
  {
    name: "apps-id-details",
    path: ":id()/details",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/apps/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: "apps-id",
    path: ":id()",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/apps/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "apps-assign-app",
    path: "assign-app",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/apps/assign-app.vue").then(m => m.default || m)
  },
  {
    name: "apps-create",
    path: "create",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/apps/create.vue").then(m => m.default || m)
  },
  {
    name: "apps",
    path: "",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/apps/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: clientsdi6PLhxvRGMeta?.name,
    path: "/clients",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/clients.vue").then(m => m.default || m),
    children: [
  {
    name: "clients-id",
    path: ":id()",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/clients/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "clients-assign-app",
    path: "assign-app",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/clients/assign-app.vue").then(m => m.default || m)
  },
  {
    name: "clients-create",
    path: "create",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/clients/create.vue").then(m => m.default || m)
  },
  {
    name: "clients",
    path: "",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "clients-setup-confirmation",
    path: "setup-confirmation",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/clients/setup-confirmation.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invitation",
    path: "/invitation",
    meta: index5v4NfaAaxwMeta || {},
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/invitation/index.vue").then(m => m.default || m)
  },
  {
    name: "invitation-received",
    path: "/invitation/received",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/invitation/received.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-01",
    path: "/onboarding/01",
    meta: _01sOoiAH1oNUMeta || {},
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/onboarding/01.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-02",
    path: "/onboarding/02",
    meta: _02lnU7J60UlbMeta || {},
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/onboarding/02.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-03",
    path: "/onboarding/03",
    meta: _03IY5bz2ZdnXMeta || {},
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/onboarding/03.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "overview-client",
    path: "/overview-client",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/overview-client.vue").then(m => m.default || m)
  },
  {
    name: "overview",
    path: "/overview",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/overview.vue").then(m => m.default || m)
  },
  {
    name: "templates-id",
    path: "/templates/:id()",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/templates/[id].vue").then(m => m.default || m)
  },
  {
    name: "templates-create",
    path: "/templates/create",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/templates/create.vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/Users/secret/Web/0Laravel42/ses-tracker-nuxt/pages/templates/index.vue").then(m => m.default || m)
  }
]