export default defineAppConfig({
  ui: {
    notifications: {
      // Show toasts at the top right of the screen
      position: 'left-1/2 transform -translate-x-1/2 md:w-1/2'
    },
    modal:{
      overlay:{
        // Show overlay when modal is opened
        background: 'bg-slate-950/80 dark:bg-slate-950/80'
      }
    },
    card:{
      body:{
        padding: 'p-4 sm:p-4'
      }
    },
    tooltip:{
      base:"h-fit"
    },
    horizontalNavigation:{
      after: 'after:inset-x-0',
      active: 'text-primary-600',
        inactive: 'text-primary-900',
    },
    tabs:{
      list:{
        background:'bg-gray-100 dark:bg-gray-600',
        tab: {
          base: 'text-gray-500 dark:text-gray-200',
          active:'text-gray-900 bg-white dark:bg-gray-800',
        }

      },
    }
  }
})